import React from "react";

interface AuthHeaderProps {
  title: string;
  subtitle?: string;
}
const AuthHeader = ({ title, subtitle }: AuthHeaderProps) => {
  return (
    <div className="text-center mb-4">
      <h3>{title}</h3>
      {subtitle && <p className="text-muted">{subtitle}</p>}
      <p className="text-muted"><a href="https://www.paypal.com/donate/?hosted_button_id=S2UNTFQ9FD8U6" target="_blank">Support us here <i className="mdi mdi-open-in-new"></i></a></p>
    </div>
  );
};

export default AuthHeader;
