import uniqid from 'uniqid';

export const writingAiText = "Writing...";
export const sendingLimitText = "You are sending too many messages. Please wait until tomorrow. if you want unlimited chat please contact: <a href='mailto:contact@kapygenius.com'>contact@kapygenius.com</a>. <br/>Thank you 😊.";
export const sendingLimit = 20;
export const messagesLimit = -45;
export const askAgainText = "Something went wrong. Please ask again. If the problem persist please contact: <a href='mailto:contact@kapygenius.com'>contact@kapygenius.com</a>, <br/> Thank you 😊.";

export function convertToHtml(str)
{
  const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
  str = str.replace(regex, '<br>');
  return str;
}

export function convertMsgToSend(str) {
  if(str === askAgainText) {
    return "Ask again.";
  }
  if(str === sendingLimitText) {
    return "No answer.";
  }
  // retun trim string
  return str.trim();
}

export function getTextPart(str="")
{
  if(str.search("```") < 0) return [{content:str, isCode: false}]
  if(str.search("```") == 0) return [{content:str.split("```")[1], isCode:true}]
  return [{content:str.split("```")[0], isCode:false}, {content:str.split("```")[1], isCode:true}, {content:str.split("```")[2], isCode:false}]
}

export const convertDataToMsg = (data) => {
    let newM = {
        mId: uniqid(),
        text: data.text && data.text,
        time: data.time,
        meta: {
          ...data.meta,
          sent: true,
          received: true,
          read: true,
        },
      };
      if (data.image && data.image.length) {
        newM["image"] = data.image;
      }
      if (data.attachments && data.attachments.length) {
        newM["attachments"] = data.attachments;
      }
      if (data.replyOf) {
        newM["replyOf"] = data.replyOf;
      }
    return newM
}

export const convertDataToMsgWriting = (data) => {
  let newM = {
      mId: uniqid(),
      text: writingAiText,
      time: data.time,
      meta: {
        ...data.meta,
        receiver: data.meta.sender,
        sender: data.meta.receiver,
        sent: true,
        received: true,
        read: true,
      },
    };
    if (data.image && data.image.length) {
      newM["image"] = data.image;
    }
    if (data.attachments && data.attachments.length) {
      newM["attachments"] = data.attachments;
    }
    if (data.replyOf) {
      newM["replyOf"] = data.replyOf;
    }
  return newM
}