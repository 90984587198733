import firebase from "firebase/compat/app";


// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/functions";
import "firebase/compat/firestore";
import "firebase/compat/analytics";

// firbase 9
import { FirebaseApp, initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, Auth, linkWithCredential, EmailAuthCredential, EmailAuthProvider, signInWithRedirect } from "firebase/auth";

import { askAgainText, convertDataToMsg, convertDataToMsgWriting, convertMsgToSend, messagesLimit, sendingLimit, sendingLimitText, writingAiText } from "./utils";



class FirebaseAuthBackend {
  public app:FirebaseApp;
  public auth:Auth;
  public googleProvider:GoogleAuthProvider;

  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      this.app = initializeApp(firebaseConfig);
      this.auth = getAuth(this.app);
      this.googleProvider = new GoogleAuthProvider();
      // Use emulator
      // if (window.location.hostname === "localhost") {
      //   firebase.firestore().useEmulator("localhost", 8081);
      //   firebase.auth().useEmulator("http://localhost:9099");
      //   firebase.functions().useEmulator("localhost", 5001)
      // }
      // Initialize Analytics and get a reference to the service
      const analytics = firebase.analytics();

      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          setLoggeedInUser(user);
        } else {
          localStorage.removeItem("authUser");
        }
      });
    }
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email, password) => {
    // call login user function

    return new Promise((resolve, reject) => {
      this.loginUser(email, password)
        .then(user => {
          resolve(user);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });

  };

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(this.auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          this.addNewUserToFirestore(user);
          resolve(user);
          // ...
        })
        .catch((error) => {
          //if user already exist then login
          if (error.code === "auth/email-already-in-use") {
            signInWithEmailAndPassword(this.auth, email, password)
              .then((userCredential) => {
                // Signed in
                const user = userCredential.user
                resolve(user);
                // ...
              })
              .catch((error) => {
                // if wrong password and email has @gmail, try to login with google
                if (error.code === "auth/wrong-password" && email.includes("@gmail")) {
                  // try to login with google and add login with email and password credentials
                  const credential = EmailAuthProvider.credential(email, password);
                  signInWithPopup(this.auth, this.googleProvider)
                    .then((result) => {
                      // This gives you a Google Access Token. You can use it to access the Google API.
                      linkWithCredential(result.user, credential)
                        .then((usercred) => {
                          // The provider has been linked to the user's account.
                          const user = usercred.user;
                          this.addNewUserToFirestore(user);
                          resolve(user);
                        })
                        .catch((error) => {
                          // if provider already linked to user
                          if (error.code === "auth/provider-already-linked") {
                            signInWithEmailAndPassword(this.auth, email, password)
                              .then((userCredential) => {
                                // Signed in
                                const user = userCredential.user
                                resolve(user);
                                // ...
                              })
                              .catch((error) => {
                                reject(this._handleError(error));
                              });
                          } else {
                            reject(this._handleError(error));
                          }
                        });
                    }) 
                    .catch((error) => {
                      reject(this._handleError(error));
                    });
                } else {
                  reject(this._handleError(error));
                }
              });
          }
          else {
            reject(this._handleError(error));
          }
        });
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = email => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Social Login user with given details
   */
  socialLoginUser = (data, type) => {
    let provider = new GoogleAuthProvider();

    if (type === "google") {
      // credential = firebase.auth.GoogleAuthProvider.credential(
      //   data.idToken,
      //   data.token
      // );
    } else if (type === "facebook") {
      // credential = firebase.auth.FacebookAuthProvider.credential(data.token);
    }
    return new Promise((resolve, reject) => {
      // if (!!credential) {
      //   firebase
      //     .auth()
      //     .signInWithCredential(credential)
      //     .then(user => {
      //       let userL = this.addNewUserToFirestore(user);
      //       userL = JSON.stringify(userL);
      //       resolve(userL);
      //     })
      //     .catch(error => {
      //       reject(this._handleError(error));
      //     });
      // } else {
      //   // reject(this._handleError(error));
      // }
      signInWithPopup(this.auth, this.googleProvider)
        .then((result) => {
          ///** @type {firebase.auth.OAuthCredential} */
          //var credential = result.credential;
          // This gives you a Google Access Token. You can use it to access the Google API.
          //var token = credential.accessToken;
          // The signed-in user info.
          // This gives you a Google Access Token. You can use it to access Google APIs.
          var user = result.user;
          let userL = this.addNewUserToFirestore(user);
          //console.log(user);
          //userL = JSON.stringify(userL);
          resolve(user);
          // ...
        }).catch((error) => {
          console.log(error)
          reject(this._handleError(error));
          // // Handle Errors here.
          // var errorCode = error.code;
          // var errorMessage = error.message;
          // // The email of the user's account used.
          // var email = error.email;
          // // The firebase.auth.AuthCredential type that was used.
          // var credential = error.credential;
          // // ...
        });
    });
  };

  addNewUserToFirestore = user => {
    const collection = firebase.firestore().collection("users");
    //const { profile } = user.additionalUserInfo;
    const details = {
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
      phoneNumber: user.phoneNumber,
      createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
      lastLoginTime: firebase.firestore.FieldValue.serverTimestamp(),
    };
    collection.doc(firebase.auth().currentUser.uid).set(details);
    return { user, details };
  };

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null;
    return JSON.parse(localStorage.getItem("authUser"));
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    errorMessage = errorMessage.replace("Firebase: ", "");
    return errorMessage;
  }


  getConversation = (id) => {
    return new Promise((resolve, reject) => {
      let conversation = {
        conversationId: 1,
        userId: id,
        typingUser: id,
        messages: [],
      }

      let user = this.getAuthenticatedUser();

      firebase.firestore().collection("conversations/" + id + "/" + user.uid)
        .orderBy("time")
        .get()
        .then(querydata => {
          querydata.forEach((doc) => {
            conversation.messages.push({ ...doc.data(), id: doc.id })
          })
          resolve(conversation)
        })
        .catch((error) =>
          reject(this._handleError(error))
        )
    })

  }

  addMessage = (data) => {
    let user = this.getAuthenticatedUser();
    return new Promise((resolve, reject) => {
      firebase.firestore().collection("conversations/" + data.meta.receiver + "/" + user.uid)
        .add({ ...convertDataToMsg(data), time: firebase.firestore.FieldValue.serverTimestamp() })
        .then((docRef) => {
          firebase.firestore().collection("conversations/" + data.meta.receiver + "/" + user.uid)
            .add({ ...convertDataToMsgWriting(data), time: firebase.firestore.FieldValue.serverTimestamp() })
            .then((docRef2) => {
              //resolve({...data, id:docRef.id} );
            })
            .catch((error) => {
              reject("Error adding document: " + error);
            });
          resolve({ ...data, id: docRef.id });
        })
        .catch((error) => {
          reject("Error adding document: " + error);
        });

    })


  }

  receiveAiMessage = (data) => {
    return new Promise((resolve, reject) => {
      let conversation = {
        conversationId: 1,
        userId: data,
        typingUser: data,
        messages: [],
      }
      let user = this.getAuthenticatedUser();

      this.getConversation(data)
        .then((conv: any) => {
          //get the conversation in String
          let convText = conv.messages.slice(-4, -1).map(msg => (msg.meta.sender == user.uid ? "Human:" : "AI:") + convertMsgToSend(msg.text) + "\n");
          let lastChat = { ...conv.messages[conv.messages.length - 1] }
          let newConv = { ...conv }

          // take last messages of today and writen by the user
          let today = new Date();
          today.setHours(0, 0, 0, 0);
          let todayMessages = conv.messages.slice(messagesLimit).filter(msg => { return msg.time ? msg.meta.sender == user.uid && msg.time.toDate() > today : false });
          // if there are more than 10 messages today, send a message to the user
          if (todayMessages.length > sendingLimit) {
            lastChat = {
              ...lastChat,
              text: sendingLimitText,
              mId: lastChat.mId + new Date().getTime(),
              time: new Date().toISOString(),
            }

            newConv.messages[newConv.messages.length - 1] = { ...lastChat }
            firebase.firestore().doc("conversations/" + data + "/" + user.uid + "/" + lastChat.id)
              .set({ ...lastChat, time: firebase.firestore.FieldValue.serverTimestamp() })
              .then((docRef) => {
                resolve(newConv)
              })
              .catch((error) => {
                reject("Error adding document: " + error);
              });
          }
          else {
            //Request und response
            let addMessage = firebase.functions().httpsCallable('addMessage');
            addMessage({ text: convText.join("") + "AI:" })
              .then(result => {
                lastChat.text = result.data;
                lastChat = {
                  ...lastChat,
                  mId: lastChat.mId + new Date().getTime(),
                  time: new Date().toISOString(),
                }
                newConv.messages[newConv.messages.length - 1] = { ...lastChat }
                firebase.firestore().doc("conversations/" + data + "/" + user.uid + "/" + lastChat.id)
                  .set({ ...lastChat, time: firebase.firestore.FieldValue.serverTimestamp() })
                  .then((docRef) => {
                    resolve(newConv)
                    console.log("AI written with ID: ", lastChat);
                  })
                  .catch((error) => {
                    console.error("Error adding document: ", error);
                  });
              })
              .catch(error => {
                // AI send error Message to user
                lastChat = {
                  ...lastChat,
                  text: askAgainText,
                  mId: lastChat.mId + new Date().getTime(),
                  time: new Date().toISOString(),
                }
                newConv.messages[newConv.messages.length - 1] = { ...lastChat }
                firebase.firestore().doc("conversations/" + data + "/" + user.uid + "/" + lastChat.id)
                  .set({ ...lastChat, time: firebase.firestore.FieldValue.serverTimestamp() })
                  .then((docRef) => {
                    resolve(newConv)
                    console.log("AI written with ID: ", lastChat);
                  })
                  .catch((error) => {
                    console.error("Error adding document: ", error);
                  });
                console.log(error);
              });
            //..........
          }
        })
        .catch((error) =>
          reject(this._handleError(error))
        )
    })
    // return new Promise((resolve, reject) => {
    //   let conversation = {
    //     conversationId: 1,
    //     userId: data,
    //     typingUser: data,
    //     messages: [],
    //   }
    //   let lastChat = null;
    //   let user = this.getAuthenticatedUser();
    //   console.log(data);
    //   resolve(conversation)
    //   firebase.firestore().collection("conversations/"+data+"/"+user.uid)
    //   .orderBy("time")
    //   .get()
    //   .then(querydata => {
    //     querydata.forEach((doc) => {
    //       conversation.messages.push({...doc.data(), id:doc.id})
    //     })


    //     lastChat = {...conversation.messages[conversation.messages.length]};
    //     lastChat = {
    //       ...lastChat,
    //       mId: lastChat.mId + new Date().getTime(),
    //       time: new Date().toISOString(),
    //       meta: {
    //         ...lastChat.meta,
    //         receiver: lastChat.meta.sender,
    //         sender: lastChat.meta.receiver,
    //       },
    //     }
    //     console.log(lastChat);
    //     firebase.firestore().collection("conversations/" + data + "/" + user.uid)
    //     .add({...lastChat, time: firebase.firestore.FieldValue.serverTimestamp()})
    //     .then((docRef) => {
    //       conversation.messages.push(lastChat)
    //       resolve(conversation);
    //       console.log("Document written with ID: ", lastChat);
    //     })
    //     .catch((error) => {
    //       console.error("Error adding document: ", error);
    //     });
    //   })
    //   .catch((error) =>
    //       reject(this._handleError(error))
    //     )
    // })

  }
}

let _fireBaseBackend = null;

const setLoggeedInUser = user => {
  localStorage.setItem("authUser", JSON.stringify(user));
};

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = config => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend, setLoggeedInUser };
